import React, { useState } from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import axios from "axios";

import { Title, Button, Box, Text, Input } from "../components/Core";
import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"


import PageWrapper from "../components/PageWrapper";
import { device } from "../utils";

import Logo from "../components/Logo";

const BoxStyled = styled(Box)`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const BoxInner = styled(Box)`
  margin-top: -65px;
  min-height: 100vh;
`;

const FormStyled = styled.div`
  padding: 40px 30px;
  @media ${device.sm} {
    padding: 53px 58px 50px;
  }
  box-shadow: ${({ theme }) => `0 20px 61px ${theme.colors.shadow}`};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.colors.light};
  width: 100%;
`;

const SignIn = ({pageContext}) => {
  const language = pageContext.language

  const [error, setError] = useState("");
  const [tenancyName, setTenancyName] = useState("");
  const intl = useIntl()


  const performSearch = () => {
    const headers = {
      "Content-Type": "application/json",
    };
    axios
      .post(
        "https://host.meftah.app/api/services/app/Account/IsTenantAvailable",
        { tenancyName: tenancyName },
        { headers }
      )
      .then(
        (
          response //this.setState({ articleId: response.data.id })
        ) => {
          const { serverRootAddress, state, tenantId } = response.data.result;

          console.log("response.data.result", response);
          console.log("serverRootAddress", serverRootAddress);

          switch (state) {
            case 1:
              window.location.href = serverRootAddress;
              return;
            case 2:
              setError("Tenant Is Not Active");
              break;
            case 3:
              setError(`There is No company defined with name ${tenancyName}`);
              break;
          }
        }
      );
  };

  return (
    <>
      <PageWrapper>
        <BoxStyled bg="#f7f8fa">
          <Box>
            <Container>
              <Row className="justify-content-center">
                <Col lg="7" xl="6">
                  <Box className="text-center" pt="40px">
                    <Logo language={language} />
                  </Box>
                </Col>
              </Row>
            </Container>
          </Box>
          <BoxInner className="d-flex align-items-center">
            <Container className="text-center">
              <Box my="100px" mx="auto">
                <Row className="justify-content-center">
                  <Col lg="7" xl="6">
                    <FormStyled>
                      <div className="mb-4">
                        <Text>
                        <FormattedMessage id="EnterYourOrganizationNameBelow" />
                        </Text>
                      </div>
                      <Box mb={3}>
                        <Input
                          type="text"
                          placeholder={intl.formatMessage({ id: "OrganizationName" })}
                          required
                          onChange={(e) => setTenancyName(e.target.value)}
                        />
                      </Box>
                      <Button
                        onClick={performSearch}
                        width="100%"
                        borderRadius={10}
                        type="button"
                      >
                       
                        <FormattedMessage id="Submit" />
                      </Button>
                      <div>
                        <p className="mt-2 text-danger">{error}</p>
                      </div>
                    </FormStyled>
                  </Col>
                </Row>
              </Box>
            </Container>
          </BoxInner>
        </BoxStyled>
      </PageWrapper>
    </>
  );
};
export default SignIn;
